$.connection.hub.url = "http://localhost:8090/signalr";

// Declare a proxy to reference the hub.
var deviceHub = $.connection.deviceHub;

function GetIpAddress(){
    $.connection.hub.start().done(function () {

        var nics = deviceHub.server.getNetworkInterfaces();
        nics.done(function (nics) {
            console.log(nics[0].IPAddress);
        });
    });
}

function Print(printContent, printerName){
    $.connection.hub.start().done(function () {
        deviceHub.server.print('spooler', printerName, printContent);
    });
}